<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
    data-app
  >
    <template v-slot:button-content>
      <i class="flaticon2-dashboard"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover min-w-md-350px px-6 pt-4">
      <v-row>
        <v-col cols="12">
          <h4>Filter:</h4>
          <v-select
            v-model="formData.sort"
            :items="sorts"
            label="Sort By"
          >
          </v-select>
          <v-select
              v-model="formData.tags"
              item-text="name"
              item-value="pk"
              :items="tags"
              chips
              label="Tags"
              multiple
          >
          </v-select>
          <v-select
            v-model="formData.project_owner"
              item-text="name"
              item-value="pk"
              :items="projectOwners"
              label="Project Owners"
          >
          </v-select>

          <div class="d-flex py-4">
            <b-button class="mr-4 ml-auto" pill @click="reset()">Reset</b-button>
            <b-button variant="primary" pill @click="filter()">Filter</b-button>
          </div>
        </v-col>
      </v-row>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import {GET_PROJECT_OWNERS, GET_PROJECT_TAGS} from "@/core/services/store/project.module";

export default {
  name: "filter-dropdown",
  components: {},
  props: ['params'],
  beforeMount() {
    this.formData = this.params;
     this.$store
          .dispatch(GET_PROJECT_OWNERS)
          .then((data) => {
            this.projectOwners = data.data;
          });
     this.$store
          .dispatch(GET_PROJECT_TAGS)
          .then((data) => {
             this.tags = data.data;
          })

  },
  data() {
    return {
       formData : null ,
       tags : [],
       projectOwners: [],
       sorts:[
         {text: 'Start Date', value: '-loss_start_date'},
         {text: 'End Date', value: '-loss_end_date'},
         {text: 'Loss Date', value: '-loss_date'},
         {text: 'Project Name', value: 'name'},
       ]
    };
  },
  methods: {
    reset(){
       this.formData = {
         search: '',
         sort: '-loss_start_date',
         tags : [],
         project_owner: [],
         favorite: true,
       }
       this.$emit('update-filters', this.formData);
    },
    filter(){
      this.$emit('update-filters', this.formData);
    }
  }
};
</script>
