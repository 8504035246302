<template>
  <div>
     <ProjectTable></ProjectTable>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProjectTable from "@/view/content/widgets/datatable/ProjectTable.vue";
import {UPDATE_ACCOUNT_INFO} from "@/core/services/store/profile.module";

export default {
  name: "projects",
  components: {
    ProjectTable
  },
  data() {
    return {
    }
  },
  mounted() {
    if(!this.currentUserAccountInfo.username){
      this.uploadAccount();
    }
  },
  methods: {
     uploadAccount(){
      this.$store
          .dispatch(UPDATE_ACCOUNT_INFO, )
          .then(() => {});
    },
  },
  computed: {
    ...mapGetters(["currentUserAccountInfo"]),
  },
};
</script>
