<template>
  <!--begin::Advance Table Widget 9-->
  <div class="row">
    <div class="col-lg-4 col-6 py-0 px-0">
      <v-card>
        <div class="d-flex justify-space-between px-4">
          <v-tabs v-model="tab" @change="loadProjects"background-color="white" color="deep-purple accent-4" right>
            <v-tab>All</v-tab>
            <v-tab>Favorite</v-tab>
          </v-tabs>
          <div class="card-toolbar pt-4 d-flex">
            <v-text-field
            class="mt-n5 pl-2 pr-6"
            v-model="filterParams.search"
            @change="loadProjects()"
            label="Search..."
            single-line
          ></v-text-field>
            <FilterDropdown :params="filterParams"  @update-filters="updateFilter"></FilterDropdown>
          </div>
        </div>
        <v-row class="mt-4 mx-2 project-list-view">
          <v-col v-for="(project, i) in teamworkProjects" :key="i" cols="6">
            <v-card class="mx-auto" max-width="344" outlined>
              <v-card-title
                class="overline mb-4 text-primary cursor-pointer"
                @click="openProject(project.teamwork_project_id)"
                >{{ project.name }}</v-card-title
              >
              <div class="text-center">
                <span
                  v-for="(item, i) in project.tags_data"
                  class="badge mr-3 mb-2"
                  :style="'background-color: '+item.color+'!important;color:white'"
                >
                  {{ item.name }}
                </span>
              </div>
              <v-card-subtitle
                class="h-auto"
                >{{ project.summary }}
              </v-card-subtitle>

              <div class="text--primary mx-5" v-if="project.client_name">
                <v-icon aria-hidden="false">
                  mdi-account-outline
                </v-icon>
                {{ project.client_name }}
              </div>


              <v-card-actions>
                <v-list-item class="grow">
                  <v-list-item-avatar color="grey darken-3">
                    <v-img
                      class="elevation-6"
                      :src="project.project_owner_avatar"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      class="pl-2"
                      >{{ project.project_owner_name }}</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-icon
                    class="mr-1 cursor-pointer"
                    :class="project.favorite ? 'text-danger' : ''"
                    @click="changeFavorite(project);project.favorite=!project.favorite"
                    >mdi-heart</v-icon
                  >
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div class="col-lg-8 col-6 py-0 px-0">
      <div id="mapDiv" ref="map" class="balt-theme"></div>
    <div id="toolbarDiv" class="esri-component esri-widget">
      <button id="distance" @click="distanceMeasurement" class="esri-widget--button esri-interactive esri-icon-measure-line" title="Distance Measurement Tool">
      </button>
      <button id="area" @click="areaMeasurement"  class="esri-widget--button esri-interactive esri-icon-measure-area" title="Area Measurement Tool">
      </button>
      <button id="clear" @click="clearMeasurements" class="esri-widget--button esri-interactive esri-icon-trash" title="Clear Measurements">
      </button>
    </div>
    </div>
  </div>
</template>

<style>
@import url("https://js.arcgis.com/4.26/@arcgis/core/assets/esri/themes/light/main.css");
</style>

<script>
import {mapGetters, mapState} from "vuex";
import {
  GET_PROJECTS, UPDATE_PROJECT, UPDATE_FAVORITE
} from "../../../../core/services/store/project.module";
import FilterDropdown from "@/view/content/dropdown/FilterDropdown.vue";

import Swal from "sweetalert2";

import {loadModules} from "esri-loader";

export default {
  name: "project-list",
  components: {
    FilterDropdown
  },
  data() {
    return {
       teamworkProjects : [],
       center: [ -102.164510, 32.944340],
       view : null,
       map: null,
       tab: 0,
       graphicsLayer : null,
       screenheight: 0,
       measurement: null,
       filterParams:{
         search: '',
         sort: '-loss_start_date',
         tags : [],
         project_owner: [],
         favorite: true,
       }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.statement.errors
    }),
    ...mapGetters(["layoutConfig", "currentUserAccountInfo"]),
  },
    methods: {
      distanceMeasurement() {
          const distanceButton = document.getElementById('distance');
          const areaButton = document.getElementById('area');
          this.measurement.activeTool = "distance";
          distanceButton.classList.add("active");
          areaButton.classList.remove("active");
      },
       areaMeasurement() {
          const distanceButton = document.getElementById('distance');
          const areaButton = document.getElementById('area');
          this.measurement.activeTool = "area";
          distanceButton.classList.remove("active");
          areaButton.classList.add("active");
        },
        clearMeasurements() {
          const distanceButton = document.getElementById('distance');
          const areaButton = document.getElementById('area');
          distanceButton.classList.remove("active");
          areaButton.classList.remove("active");
          this.measurement.clear();
          },
      addMeasurements(){
         loadModules([
            'esri/widgets/Measurement'
        ], {
            css: true
          })
          .then(([Measurement]) => {
             const measurement = new Measurement({
              view: this.view,
            });
            this.view.ui.add(measurement, "top-bottom");
            this.measurement = measurement;
          });
      },
      loadMap(){
        loadModules([
            'esri/Map',
            'esri/views/MapView',
             'esri/layers/GraphicsLayer'
        ], {
            css: true
          })
          .then(([Map, MapView, GraphicsLayer]) => {
            this.map= new Map({
              basemap: "streets"
            });
            this.view = new MapView({
              container: this.$refs.map,
              map: this.map,
              center: this.center,
              zoom: 5
            });
            this.view.popup.autoOpenEnabled = false;

            this.view.on("click", (event) => {
              let view = this.view;
              this.view.hitTest(event)
                  .then(function(response){
                    if(response.results.length >0 && response.results[0].hasOwnProperty('graphic')  && response.results[0].graphic.hasOwnProperty('attributes'))
                    {
                      view.popup.content = `<p><b>Summany:</b>  ${response.results[0].graphic.attributes.summary}</p>`
                          +`<p><b>Loss Location: </b> <span>${response.results[0].graphic.attributes.loss_full_location}</span></p>`
                          +`<p><b>Loss Date: </b> ${response.results[0].graphic.attributes.loss_date_formatted}</p>`
                          +`<p><b>Project Duration: </b> ${response.results[0].graphic.attributes.loss_start_date_formatted} - ${response.results[0].graphic.attributes.loss_end_date_formatted}</p>`
                          +`<p><b>Client Name: </b> ${response.results[0].graphic.attributes.client_name}</p>`
                          +`<p><b>View: </b> <a target="_blank" href="${window.location.href}/detail/${response.results[0].graphic.attributes.teamwork_project_id}">${response.results[0].graphic.attributes.teamwork_project_id}</a></p>`;
                      view.popup.open({
                        title: response.results[0].graphic.attributes.name,
                        location: event.mapPoint
                      });

                    }
                  });
            });

            this.graphicsLayer = new GraphicsLayer();
            this.map.add(this.graphicsLayer);

            this.addMeasurements();
            this.loadProjects()

          });
     },
     loadProjects(){
         this.filterParams.favorite = this.tab == 1 ? true : false
         this.$store
          .dispatch(GET_PROJECTS, this.filterParams)
          .then((data) => {
            this.teamworkProjects = data;
            this.addMarkers();
          })
      },
    openProject(teamwork_project_id){
        const route = {
             name: "project-detail",
             params: { id: teamwork_project_id }
           };
           this.$router.push(route);
    },
    changeFavorite(project){
        this.$store
          .dispatch(UPDATE_FAVORITE, {
            pk: project.pk,
          })
          .then((data) => {
          })
    },
    updateFilter(newFilters){
        this.$refs.map.click();
        this.filterParams = newFilters;
        this.loadProjects();
    },
    addMarkers(){
        loadModules(['esri/Graphic'], {
            css: true
          })
          .then(([Graphic]) => {
            if(this.graphicsLayer){
              this.graphicsLayer.removeAll();
            }
        for( let i= 0; i < this.teamworkProjects.length ; i ++){
          if(!this.teamworkProjects[i]['loss_lng']) continue;
           const point = {
             type: "point",
             longitude: this.teamworkProjects[i]['loss_lng'],
             latitude: this.teamworkProjects[i]['loss_lat'],
           };
           const simpleMarkerSymbol = {
             type: "simple-marker",
             color: [226, 119, 40],  // Orange
             outline: {
               color: [255, 255, 255],
               width: 1
             }
           };
           const pointGraphic = new Graphic({
             geometry: point,
             symbol: simpleMarkerSymbol,
             attributes: this.teamworkProjects[i]
           });
           this.graphicsLayer.add(pointGraphic);
        }
          });

    },
  },
  beforeMount() {

  },
  mounted() {
    this.loadMap();
  },
  watch: {

  },
  created() {
  },
};
</script>
<style>
.v-avatar {
  height: 35px !important;
  min-width: 35px !important;
  width: 35px !important;
}
#mapDiv {
  width: 100%;
  height: calc(100vh - 110px) !important;
}

.project-list-view {
  overflow-y: scroll;
  height: calc(100vh - 170px) !important;
}
@media (min-width: 992px) {
  .header-fixed.subheader-fixed.subheader-enabled .wrapper {
    padding-top: 90px;
  }
  .esri-feature__content-node p{
    margin-bottom: 8px!important;
  }
}
 #toolbarDiv {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: default;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }




      .esri-widget--button.active,
      .esri-widget--button.active:hover,
      .esri-widget--button.active:focus {
        cursor: default;
        background-color: #999696;
      }
      .esri-widget--button.active path,
      .esri-widget--button.active:hover path,
      .esri-widget--button.active:focus path {
        fill: #E4E4E4;
      }
</style>
